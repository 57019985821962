import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import parse from 'html-react-parser';
import * as Yup from 'yup';
import { faComments } from '@fortawesome/pro-duotone-svg-icons';
import { faCircleInfo, faPaperPlaneTop } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useSelector } from 'react-redux';
import { List, Input } from 'antd';
import styled from 'styled-components';
import CenteredLoader from '../Loader/CenteredLoader';
import Dropdown from '../Dropdown/Dropdown';
import ConfirmModal from '../Modal/ConfirmModal';
import Button from '../Button/Button';
import Avatar from '../AvatarEditor/Avatar';
import { Paragraph, Description, Title } from '../Text/Text';
import { formatSinceTime, getInitials } from '../../lib/helpers/helper';

import useCRUD from '../../_Hooks/useCRUD';
import useFormState from '../../_Hooks/useFormState';
import { colors, Div, fonts, spaces } from '../../styles/style';
import Label from '../Label/Label';
import TooltipIcon from '../Tooltip/TooltipIcon';

const StyledInput = styled(Input)`
  padding: ${spaces.space0} ${spaces.space0} ${spaces.space0} ${spaces.space2};
  border-color: ${colors.neutral100};

  &:hover,
  &:active {
    border-color: ${colors.neutral200};
  }
`;

const schema = Yup.object().shape({
  comment: Yup.string()
    .required('Campo obrigatório')
    .test({
      test: value => value?.replace(/<("[^"]*?"|'[^']*?'|[^'">])*>/g, '').length > 0
    })
});
const initialState = {
  comment: ''
};

const CommentListV2 = ({ data, idRefurbish, model, tooltipText, isMobile, ...props }) => {
  const { formState, handleChange, isValid, reset } = useFormState(initialState, schema);
  const { values } = formState;
  const { comment } = values;
  const { user } = useSelector(state => state.authReducer);
  const [limit, setLimit] = useState(10);

  const { list: comments, handleGet, totalItems, handleCreate, loading } = useCRUD({
    model,
    immediatelyLoadData: false
  });

  const _handleGet = () => {
    return handleGet({
      refetchOptions: {
        include: [{ model: 'comments', paranoid: false }],
        where: { id: data?.id, idRefurbish },
        limit,
        order: [['comments', 'createdAt', 'asc']]
      }
    });
  };

  const handleComment = () => {
    if (!isValid() || user.isCustomerView) return;

    handleCreate({
      postPathOptions: `/comments`,
      values: { comment, user, idRefurbish, idData: data.id, model },
      refresh: false
    }).then(resp => {
      if (resp?.error) return false;
      reset(initialState);
      return _handleGet();
    });
  };

  useEffect(() => {
    setLimit(10);
  }, [data]);

  useEffect(() => {
    _handleGet();
  }, [data, limit]);

  const _comments = comments[0];

  const loadMore =
    _comments?.comments?.length && _comments?.comments?.length < totalItems ? (
      <List.Item>
        <Button ghost fullWidth type="primary" size="small" shape="text" onClick={() => setLimit(limit + 10)}>
          Carregar mais comentários
        </Button>
      </List.Item>
    ) : null;

  const isCommentDisabled = user.isCustomerView || comment.length === 0;

  if (loading && !comments?.length) {
    return <CenteredLoader />;
  }

  return (
    <>
      <Div direction="column" display="flex" height={!isMobile ? 'calc(100% - 74px)' : '450px'} $fullWidth {...props}>
        {!isMobile && (
          <Div
            $fullWidth
            direction="row"
            align="center"
            gap={spaces.space1}
            padding={spaces.space2}
            borderBottom={`1px solid ${colors.neutral100}`}
          >
            <>
              <Title weight={fonts.weight600} ellipsis>
                {data?.filename || 'Comentários'}
              </Title>
              <TooltipIcon icon={faCircleInfo} iconColor={colors.primary500} text={tooltipText} />
            </>
          </Div>
        )}
        <Div
          direction="column"
          align="start"
          $fullWidth
          overflow="auto"
          height="100%"
          $backgroundColor={!isMobile ? colors.neutral50 : colors.white}
        >
          <List
            level={3}
            loadMore={loadMore}
            style={{ padding: !isMobile ? `0 ${spaces.space2}` : '0px', width: '100%' }}
          >
            {_comments?.comments?.length ? (
              _comments?.comments?.map(item => {
                const isMine = item.user && item.user.id === user.id;
                const timeToShow = formatSinceTime(item.createdAt);
                return (
                  <List.Item key={item.id} style={{ border: 0, padding: 0, width: '100%' }}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginTop: spaces.space3,
                        flexDirection: 'row',
                        width: '100%',
                        flex: 1
                      }}
                    >
                      <Div direction="row" align="start">
                        <div style={{ marginRight: spaces.space1 }}>
                          <Avatar size="small" src={item.user?.avatarFullpath}>
                            <Label color={colors.white}>{getInitials(item.user?.name)}</Label>
                          </Avatar>
                        </div>
                        <Div direction="column" align="start" style={{ flex: 1, gap: spaces.space0 }}>
                          <Div center="start" gap={spaces.space1}>
                            <Paragraph ellipsis type="small" weight={fonts.weight600}>
                              {item.user.name}
                            </Paragraph>
                            <Description color={colors.neutral500}>{timeToShow}</Description>
                          </Div>
                          <Paragraph style={{ width: '100%' }}>{parse(item.comment)}</Paragraph>
                        </Div>
                      </Div>
                      {isMine && (
                        <Div align="end">
                          <Dropdown
                            padding={spaces.space1}
                            slim
                            model="comments"
                            data={item}
                            trigger={['click']}
                            modal={{
                              confirmModal: ConfirmModal
                            }}
                            afterSubmit={() => {
                              _handleGet();
                            }}
                          />
                        </Div>
                      )}
                    </div>
                  </List.Item>
                );
              })
            ) : (
              <Div
                direction="column"
                $fullWidth
                $fullHeight
                $textAlign="center"
                gap={spaces.space2}
                margin={`${spaces.space7} 0 0 0`}
              >
                <Div width={spaces.space8} height={spaces.space8} align="center" justify="center">
                  <FontAwesomeIcon icon={faComments} color={colors.neutral400} fontSize={fonts.sizeH3} />
                </Div>
                <Label>Pergunte, comente e colabore! Acompanhe as atualizações do seu projeto.</Label>
              </Div>
            )}
          </List>
        </Div>
      </Div>
      {!user?.anonymous && (
        <Div
          $fullWidth
          direction="column"
          $backgroundColor={!isMobile ? colors.neutral50 : colors.white}
          padding={!isMobile ? spaces.space2 : `${spaces.space2} 0px 0px 0px`}
        >
          <StyledInput
            id="input-comment"
            placeholder="Adicione um comentário"
            value={comment}
            onChange={handleChange('comment')}
            onPressEnter={handleComment}
            className="form-component"
            suffix={
              <Button
                width={spaces.space4}
                height={spaces.space4}
                type={isCommentDisabled ? 'neutral' : 'primary'}
                id="send-comment"
                style={{ border: 0 }}
                onClick={handleComment}
                disabled={isCommentDisabled}
              >
                <FontAwesomeIcon icon={faPaperPlaneTop} color={isCommentDisabled ? colors.neutral400 : colors.white} />
              </Button>
            }
          />
        </Div>
      )}
    </>
  );
};

CommentListV2.propTypes = {
  data: PropTypes.instanceOf(Object),
  idRefurbish: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  model: PropTypes.string,
  tooltipText: PropTypes.string,
  isMobile: PropTypes.bool
};

export default CommentListV2;
