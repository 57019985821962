import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/pro-regular-svg-icons';

import useViewport from '../../_Hooks/useViewport';
import useCRUD from '../../_Hooks/useCRUD';

import { Container, UserList, EyeContainer, WatcherCheckbox } from './Watcher.styles';
import { colors, spaces } from '../../styles/style';
import Button from '../Button/Button';

const Watcher = ({ idTask, idCompany }) => {
  const [data, setData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [numberOfWatchers, setNumberOfWatchers] = useState(0);
  const [isHovered, setIsHovered] = useState(false);

  const hasWatcher = numberOfWatchers > 0;

  const { isMobile } = useViewport(window.innerWidth);

  const _isMobile = isMobile();

  const { list: userList } = useCRUD({
    model: 'user',
    options: {
      include: [
        {
          model: 'task',
          as: 'tasks',
          where: { id: idTask },
          required: false
        }
      ],
      where: { userType: 'provider', idCompany },
      order: [['name', 'ASC']]
    },
    immediatelyLoadData: !!idTask
  });

  const { handleCreate: handleAddWatcher } = useCRUD({
    model: 'task',
    pathOptions: '/watcher',
    immediatelyLoadData: false
  });

  const { handleDelete: handleRemoveWatcher } = useCRUD({
    model: `task/${idTask}`,
    immediatelyLoadData: false
  });

  useEffect(() => {
    let nWatcher = 0;
    const newData = userList.map(user => {
      if (user.tasks[0]) nWatcher++;
      return { id: user.id, name: user.name, checked: !!user.tasks[0] };
    });
    setData(newData);
    setNumberOfWatchers(nWatcher);
  }, [userList]);

  const handleBlur = event => {
    if (!event.currentTarget.contains(event.relatedTarget) && !_isMobile) {
      setIsOpen(false);
    }
  };

  const handleChangeCB = (e, id) => {
    const { checked } = e.target;
    const item = data.find(d => (d.id || d.value) === id);
    item.checked = checked;

    if (checked) {
      handleAddWatcher({ values: { idTask, idWatcher: item.id }, refresh: false });
    } else {
      handleRemoveWatcher({ deletePathOptions: `/watcher/${item.id}` });
    }

    setNumberOfWatchers(state => (checked ? state + 1 : state - 1));
    setData(data);
  };

  return (
    <Container
      onBlur={handleBlur}
      tabIndex={0}
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Button
        type="iconNeutral"
        justify="center"
        width={spaces.space4}
        height={spaces.space4}
        onClick={() => setIsOpen(!isOpen)}
        style={{ position: 'relative' }}
      >
        <EyeContainer>
          {hasWatcher && <div />}
          <Tooltip
            visible={!isOpen && isHovered}
            placement="bottom"
            // eslint-disable-next-line
            title="Você pode adicionar membros da sua equipe como observadores para receber notificações sobre esta tarefa."
            mouseEnterDelay={0.6}
          >
            <FontAwesomeIcon icon={faEye} />
          </Tooltip>
        </EyeContainer>
      </Button>
      {isOpen && (
        <UserList role="presentation">
          {data.map(item => (
            <li key={item.id}>
              <WatcherCheckbox
                onChange={e => handleChangeCB(e, item.id || item.value)}
                checked={item.checked}
                defaultChecked={item.checked}
              />
              <label style={{ color: colors.neutral600 }}>{item.name}</label>
            </li>
          ))}
        </UserList>
      )}
    </Container>
  );
};

Watcher.propTypes = {
  idTask: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  idCompany: PropTypes.number
};

export default Watcher;
